import { usePopup } from '@/compositions/popup';
import { genMenuItemEdit } from '@/utils/list-generators';
import { objectPropertyService } from '@/modules/common/api';

export function usePositionItemMenu() {
  const popup = usePopup();

  return {
    genMenu: property => {
      const { id } = property;
      return [
        genMenuItemEdit(() => {
          popup.open({
            component: () => import('@/components/popup/EditProperty.vue'),
            props: {
              currentItem: property,
              onConfirm: value => {
                objectPropertyService.update(id, {
                  value: value
                });
              }
            }
          });
        })
      ];
    }
  };
}
